<template>
  <div class="container-box">
    <div class="box-left" v-loading="loading" :element-loading-text="loadingText">
      <div class="guide-box">
        <span class="numBox">
          <span class="num1">1</span>
          <span class="num2">/</span>
          <span class="num2">3</span>
        </span>
        <span class="txt">你报考的信息是？</span>
      </div>
      <div class="dark-border">
        <div class="form-box">
          <smart-adjust-form ref="smartAdjustForm" :formData.sync="formData"></smart-adjust-form>
          <div class="button">
            <el-button size="small" type="primary" @click="onSubmitAnalyze">开始调剂分析</el-button>
          </div>
        </div>
      </div>
      <div class="guide-box">
        <span class="numBox">
          <span class="num1">2</span>
          <span class="num2">/</span>
          <span class="num2">3</span>
        </span>
        <span class="txt">你2024年的调剂分析</span>
      </div>
      <div v-if="analysisResult" class="dark-border">
        <div class="result-box">
          <header-entry :img-size="16">
            <div class="title">国家线和学校复试线</div>
          </header-entry>
          <div class="row_box">
            <el-row type="flex" :gutter="5" v-if="analysisResult.VolunteerMinScore">
              <el-col :span="6">
                <div class="txt" style="text-align: right;">一志愿录取最低：</div>
              </el-col>
              <el-col :span="18">
                <div class="txt">{{ analysisResult.UniversityName }} {{ analysisResult.CollegeName }} {{ analysisResult.MajorCode }}{{ analysisResult.MajorName }} {{ analysisResult.VolunteerMinScore }}</div>
              </el-col>
            </el-row>
            <el-row type="flex" :gutter="5">
              <el-col :span="6">
                <div class="txt" style="text-align: right;">学校复试线：</div>
              </el-col>
              <el-col :span="18">
                <div class="txt">{{ analysisResult.UniversityName }} {{ analysisResult.CollegeName }} {{ analysisResult.MajorCode }}{{ analysisResult.MajorName }} {{ analysisResult.RetestLine }}</div>
              </el-col>
            </el-row>
            <el-row type="flex" :gutter="5">
              <el-col :span="6">
                <div class="txt" style="text-align: right;">国家线：</div>
              </el-col>
              <el-col :span="18">
                <div class="txt">{{ analysisResult.MajorCode }}{{ analysisResult.MajorName }} {{ analysisResult.NationalLine }}</div>
              </el-col>
            </el-row>
            <el-row type="flex" :gutter="5">
              <el-col :span="6">
                <div class="txt" style="text-align: right;">跨学科调剂A区国家线：</div>
              </el-col>
              <el-col :span="18">
                <div class="txt">{{ analysisResult.NationalLineA }}</div>
              </el-col>
            </el-row>
            <el-row type="flex" :gutter="5">
              <el-col :span="6">
                <div class="txt" style="text-align: right;">跨学科调剂B区国家线：</div>
              </el-col>
              <el-col :span="18">
                <div class="txt">{{ analysisResult.NationalLineB }}</div>
              </el-col>
            </el-row>
          </div>
          <div class="bottom-line"></div>
          <header-entry :img-size="16">
            <div class="title">调剂分析结果</div>
          </header-entry>
          <div class="row_box">
            <template v-if="analysisResult.AnalysisVerdict">
              <div class="txt">{{ analysisResult.AnalysisVerdict }}</div>
            </template>
            <div v-else class="empty-txt">暂无</div>
          </div>
          <div class="bottom-line"></div>

          <template v-if="analysisResult.AdjustDirections.length > 0">
            <header-entry :img-size="16">
              <div class="title">调剂去向</div>
              <el-image v-if="!isMembership" :src="lockIcon" class="adjust-lock" @click="onFormUnlockClick"></el-image>
            </header-entry>
            <div v-if="isMembership" class="content-admit">
              <el-tabs :value="analysisResult.AdjustDirections[0].AdjustYear" type="card" class="content-tabs">
                <el-tab-pane class="content-pane" v-for="(item, index) in analysisResult.AdjustDirections" :key="index" :label="item.AdjustYear + '年'" :name="item.AdjustYear">
                  <div class="content-adjust-title">调剂录取院校-过国家线未被本专业录取考生最终调剂去向学校</div>
                  <div class="content-adjust-text" v-for="(item, index) in adjustDirectionSchools(item.AdjustDirectionSchool)" :key="index">{{ item }}</div>
                  <div class="content-admit">
                    <el-image v-if="adjustRosterImage(item.AdjustYear)" :src="adjustRosterImage(item.AdjustYear)" :preview-src-list="previewAdjustRosterImages(item.AdjustYear)" class="content-admit-img"></el-image>
                  </div>
                  <div class="content-tip">
                    <span>温馨提示: 点击图片可以查看高清原图。</span>
                  </div>
                </el-tab-pane>
              </el-tabs>
            </div>
            <div class="bottom-line"></div>
          </template>

          <header-entry :img-size="16">
            <div class="title">可调剂省区及学位类别</div>
          </header-entry>
          <div class="row_box">
            <template v-if="analysisResult.AdjustPartitionDegreeTypes && analysisResult.AdjustPartitionDegreeTypes.length > 0">
              <div v-for="(item, index) in analysisResult.AdjustPartitionDegreeTypes" :key="index" class="txt">{{ item.Partition }}区省份 {{ item.DegreeType }}</div>
            </template>
            <div v-else class="empty-txt">暂无</div>
          </div>
          <div class="bottom-line"></div>
          <header-entry :img-size="16">
            <div class="title">可调剂专业</div>
          </header-entry>
          <div class="row_box">

            <template v-if="majorGroup">
              <div v-for="(val, key) in majorGroup" class="major_box">
                <div class="major1">{{ key }}</div>
                <div class="major2_box">
                  <div v-for="(item, index) in val" :key="index" class="major2">{{ item.MajorCode }}{{ item.MajorName }}</div>
                </div>
              </div>
            </template>
            <div v-else class="empty-txt">暂无</div>
          </div>
        </div>
      </div>
      <div v-else class="dark-border">
        <div class="empty-txt">请正确填写报考信息，点“开始调剂分析”获取数据。</div>
      </div>
      <div class="guide-box">
        <span class="numBox">
          <span class="num1">3</span>
          <span class="num2">/</span>
          <span class="num2">3</span>
        </span>
        <span class="txt">以下是为你推荐的调剂院校</span>
      </div>
      <div v-if="analysisResult">
        <div class="tabs-box" v-if="analysisResult.AdjustMajors && analysisResult.AdjustMajors.length > 0">
          <el-tabs class="school-tabs" type="card" v-model="active" @tab-click="onTabChange">
            <el-tab-pane :label="`全部（${countAll}）`"></el-tab-pane>
            <el-tab-pane :label="`较稳妥（${countSafer}）`"></el-tab-pane>
            <el-tab-pane :label="`可冲击（${countStrike}）`"></el-tab-pane>
            <el-tab-pane :label="`其他相关（${countOther}）`"></el-tab-pane>
            <el-tab-pane :label="`对比（${countCompare}）`"></el-tab-pane>
          </el-tabs>
          <el-button type="primary" size="small" class="report-button" @click="onShowStudent">生成调剂报告</el-button>
          <div class="school-list">

            <template v-if="active != 4">
              <div class="school-filter">
                <smart-adjust-filter :learningWayList="filterLearningWayList" :degreeTypeList="filterDegreeTypeList" :belongCategoryList="filterBelongCategoryList" :firstDisciplineList="filterFirstDisciplineList" :adjustMajorList="filterAdjustMajorList" :aProvinceList="filterAProvinceList" :bProvinceList="filterBProvinceList" :filter1.sync="filter1" :filter2.sync="filter2" :filter3.sync="filter3" :filter4.sync="filter4" :filter5.sync="filter5" :filter6.sync="filter6" :filter7.sync="filter7" @filter="onFilterClick"></smart-adjust-filter>
              </div>
              <template v-if="admitInfos.length > 0">
                <div v-for="(admit, index) in admitInfos" :key="index">
                  <smart-adjust-item :school="admit.UniversityName" :college="admit.CollegeName" :major="admit.MajorName" :code="admit.MajorCode" :province="admit.UniversityProvince" :partition="admit.UniversityPartition" :characteristic="admit.UniversityCharacteristic" :year="admit.MajorEnrollmentYear" :learnway="admit.MajorLearningWay" :isUnlocked="isMembership" :isAdjust="admit.IsAdjust" :admitInfoList="admit.AdmitInfos" :compareVisible="compareVisible(admit)" @item-click="onItemClick(admit)" @unlock-click="onItemUnlockClick(admit)" @adjust-click="onItemAdjustClick(admit)" @compare-click="onAddCompareClick(admit)"></smart-adjust-item>
                  <div v-if="index < admitInfos.length - 1" class="bottom-line"></div>
                </div>
              </template>
              <div v-else class="empty-txt">无</div>
            </template>

            <template v-else>
              <el-table :data="tableData" border>
                <el-table-column fixed align="center" prop="column" label="院校" width="110">
                </el-table-column>
                <el-table-column v-for="(column, index) in tableColumns" :key="index" :prop="column.prop" :label="column.label" width="200">
                  <template slot="header" slot-scope="scope">
                    <div>
                      <span>{{ column.label }}</span>
                      <i class="el-icon-close" title="移除" @click="onDelCompareClick(index)"></i>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </div>
        </div>
        <div v-else class="dark-border">
          <div class="empty-txt">暂无</div>
        </div>
      </div>
      <div v-else class="dark-border">
        <div class="empty-txt">请正确填写报考信息，点“开始调剂分析”获取数据。</div>
      </div>
      <div class="pagination-box">
        <el-pagination background hide-on-single-page :current-page.sync="pageIndex" :page-size.sync="pageSize" :total.sync="totalCount" layout="total, prev, pager, next, jumper" @current-change="onCurrentChange"></el-pagination>
      </div>
    </div>
    <div class="box-right">
      <school-hot></school-hot>
    </div>

    <el-dialog center destroy-on-close :close-on-click-modal="true" :close-on-press-escape="true" :show-close="true" :visible.sync="studentDialog" title="填写个人信息" width="400px">
      <student-dialog2 @submit="onSubmitReport" @cancel="onCancelReport"></student-dialog2>
    </el-dialog>
  </div>
</template>

<script>
import SmartAdjustForm from "@/components/PC/SmartAdjustForm";
import SmartAdjustFilter from "@/components/PC/SmartAdjustFilter";
import SmartAdjustItem from "@/components/PC/SmartAdjustItem";
import HeaderEntry from "@/components/PC/HeaderEntry";
import SchoolHot from "@/components/PC/SchoolHot";
import SearchControl from "@/components/PC/SearchControl";
import StudentDialog2 from "@/components/PC/StudentDialog2";
import Config from "@/api/service";
import Tools from "@/tool";
import Manager from "@/manager";
export default {
  name: "PCModule",
  components: {
    SmartAdjustForm,
    SmartAdjustFilter,
    SmartAdjustItem,
    HeaderEntry,
    SchoolHot,
    SearchControl,
    StudentDialog2,
  },
  data() {
    return {
      formData: {
        universityCode: null,
        collegeCode: null,
        majorCode: null,
        majorLearningWay: '全日制',
        politicalScore: null,
        englishScore: null,
        majorScore1: null,
        majorScore2: null,
      },
      isMembership: false,
      loading: false,
      loadingText: null,
      studentDialog: false,
      btnSendDisabled: false,
      active: 0,
      percentage: 0,
      pageIndex: 1,
      pageSize: 10,
      totalCount: 0,
      countAll: 0,
      countSafer: 0,
      countStrike: 0,
      countOther: 0,
      filter1: [],
      filter2: [],
      filter3: '',
      filter4: [],
      filter5: [],
      filter6: [],
      filter7: '',
      admitInfos: [],
      admitCompares: [],
      analysisParams: null,
      analysisResult: null,
      analysisFilter: null,
      lockIcon: require('@/assets/images/comm/lock.png'),
    };
  },
  computed: {
    compareVisible() {
      return function (admitInfo) {
        return this.admitCompares.filter(p => p.AdmitGuid == admitInfo.AdmitGuid).length == 0 && this.isMembership;
      }
    },
    countCompare() {
      return this.admitCompares.length;
    },
    tableColumns() {
      var columns = [];
      for (var i = 0; i < this.admitCompares.length; i++) {
        columns.push({
          prop: 'column' + i,
          label: this.admitCompares[i].UniversityName,
        });
      }
      return columns;
    },
    tableData() {
      var data = [
        { column: "学院" },
        { column: "专业" },
        { column: "研究方向" },
        { column: "初试科目" },
        { column: "复试科目" },
        { column: "拟招生人数" },
        { column: "录取年份" },
        { column: "录取总人数" },
        { column: "一志愿录取" },
        { column: "一志愿最低分" },
        { column: "一志愿最高分" },
        { column: "目标分数" },
        { column: "调剂人数" },
        { column: "调剂最低分" },
        { column: "调剂最高分" },
      ];
      for (var i = 0; i < this.admitCompares.length; i++) {
        data[0]['column' + i] = this.admitCompares[i].CollegeName;
        data[1]['column' + i] = this.admitCompares[i].MajorCode + this.admitCompares[i].MajorName;
        data[2]['column' + i] = this.admitCompares[i].MajorResearchDirection;
        data[3]['column' + i] = this.admitCompares[i].MajorFirstSubject;
        data[4]['column' + i] = this.admitCompares[i].MajorSecondSubject;
        data[5]['column' + i] = this.admitCompares[i].MajorPlannedEnrollment;
        data[6]['column' + i] = this.admitCompares[i].AdmitYear;
        data[7]['column' + i] = this.admitCompares[i].AdmitTotalNumber;
        data[8]['column' + i] = this.admitCompares[i].AdmitVolunteerNumber;
        data[9]['column' + i] = this.admitCompares[i].AdmitVolunteerMinScore;
        data[10]['column' + i] = this.admitCompares[i].AdmitVolunteerMaxScore;
        data[11]['column' + i] = this.admitCompares[i].AdmitTargetScore;
        data[12]['column' + i] = this.admitCompares[i].AdmitAdjustedNumber;
        data[13]['column' + i] = this.admitCompares[i].AdmitAdjustMinScore;
        data[14]['column' + i] = this.admitCompares[i].AdmitAdjustMaxScore;
      }
      return data;
    },
    majorGroup() {
      if (this.analysisResult && this.analysisResult.AdjustMajors) {
        var groups = {};
        groups = this.analysisResult.AdjustMajors.reduce((acc, currentValue) => {
          let key = currentValue.MajorFirstDiscipline;
          if (!acc[key]) {
            acc[key] = [];
          }
          acc[key].push(currentValue);
          return acc;
        }, {});
        return groups;
      }
      return null;
    },
    totalScore() {
      var total = Number(this.formData.politicalScore) + Number(this.formData.englishScore) + Number(this.formData.majorScore1) + Number(this.formData.majorScore2);
      if (isNaN(total)) {
        return 0;
      } else {
        return total;
      }
    },
    adjustRosterImage() {
      return function (adjustYear) {
        if (this.analysisParams && this.analysisParams.universityCode && this.analysisParams.collegeCode && this.analysisParams.majorCode && adjustYear && this.$store.getters.isLogin) {
          return Config.smartAdjust.adjustRosterImage + `?UniversityCode=${this.analysisParams.universityCode}&CollegeCode=${this.analysisParams.collegeCode}&MajorCode=${this.analysisParams.majorCode}&AdjustYear=${adjustYear}&Token=${this.$store.state.token}`;
        }
        return null;
      }
    },
    previewAdjustRosterImages() {
      return function (adjustYear) {
        return [this.adjustRosterImage(adjustYear)];
      }
    },
    adjustDirectionSchools() {
      return function (adjustDirectionSchool) {
        if (adjustDirectionSchool) {
          return adjustDirectionSchool.split('；');
        } else {
          return "";
        }
      }
    },
    filterLearningWayList() {
      return this.analysisFilter ? this.analysisFilter.LearningWays : [];
    },
    filterDegreeTypeList() {
      return this.analysisFilter ? this.analysisFilter.DegreeTypes : [];
    },
    filterBelongCategoryList() {
      return this.analysisFilter ? this.analysisFilter.Belongcategorys : [];
    },
    filterFirstDisciplineList() {
      if (this.filter3) {
        //获取分类前两位
        var category = this.filter3.substring(0, 2);
        //将匹配的专业排在首位
        this.analysisResult.AdjustMajors.sort((a, b) => {
          if (a.MajorCode === this.analysisResult.MajorCode && b.MajorCode !== this.analysisResult.MajorCode) {
            return -1;
          }
          if (a.MajorCode !== this.analysisResult.MajorCode && b.MajorCode === this.analysisResult.MajorCode) {
            return 1;
          }
          return 0;
        });
        //过滤学科类别
        var items = this.analysisResult.AdjustMajors.filter(p => p.MajorFirstDiscipline.startsWith(category));
        var arr = items.map(p => p.MajorFirstDiscipline);
        var result = [...new Set(arr)];
        return result;
      }
      return [];
    },
    filterAdjustMajorList() {
      if (this.filter4.length > 0) {
        var result = [];
        for (const item of this.filter4) {
          var items = this.analysisResult.AdjustMajors.filter(p => p.MajorFirstDiscipline == item);
          result.push(...items);
        }
        return result;
      }
      return [];
    },
    filterAProvinceList() {
      return this.analysisFilter ? this.analysisFilter.AProvinces : [];
    },
    filterBProvinceList() {
      return this.analysisFilter ? this.analysisFilter.BProvinces : [];
    },
  },
  methods: {
    //获取路由参数
    getRoutParams() {
      if (this.$route.query.universityCode)
        this.formData.universityCode = this.$route.query.universityCode;
      if (this.$route.query.collegeCode)
        this.formData.collegeCode = this.$route.query.collegeCode;
      if (this.$route.query.majorCode)
        this.formData.majorCode = this.$route.query.majorCode;
      if (this.$route.query.majorLearningWay)
        this.formData.majorLearningWay = this.$route.query.majorLearningWay;
      if (this.$route.query.politicalScore)
        this.formData.politicalScore = this.$route.query.politicalScore;
      if (this.$route.query.englishScore)
        this.formData.englishScore = this.$route.query.englishScore;
      if (this.$route.query.majorScore1)
        this.formData.majorScore1 = this.$route.query.majorScore1;
      if (this.$route.query.majorScore2)
        this.formData.majorScore2 = this.$route.query.majorScore2;
    },
    //获取是否购买大数据会员
    getIsMembership() {
      if (!this.$store.getters.isLogin) {
        return
      }
      var model = {};
      this.$axios
        .post(Config.member.purchased, model)
        .then((res) => {
          this.isMembership = res.data.Data.IsPurchased;
        })
        .catch((error) => {
          this.$message.error(error.message);
        });
    },
    //获取分析结果
    getAnalysisResult() {
      this.filter1 = [];
      this.filter2 = [];
      this.filter3 = '';
      this.filter4 = [];
      this.filter5 = [];
      this.filter6 = [];
      this.filter7 = '';
      this.analysisResult = null;
      this.analysisParams = Tools.clone(this.formData);
      this.loadingText = '正在分析中...';
      this.loading = true;
      this.pageIndex = 1;
      this.totalCount = 0;
      var model = {
        UniversityCode: this.analysisParams.universityCode,
        CollegeCode: this.analysisParams.collegeCode,
        MajorCode: this.analysisParams.majorCode,
        MajorLearningWay: this.analysisParams.majorLearningWay,
        PoliticalScore: this.analysisParams.politicalScore,
        EnglishScore: this.analysisParams.englishScore,
        MajorScore1: this.analysisParams.majorScore1,
        MajorScore2: this.analysisParams.majorScore2,
      };
      return this.$axios
        .post(Config.smartAdjust.analysisResult, model)
        .then((res) => {
          this.loading = false;
          this.analysisResult = res.data.Data;
        })
        .catch((error) => {
          this.loading = false;
          this.$message.error(error.message);
        });
    },
    //获取分析结果过滤条件
    getFilterConditions() {
      if (this.analysisResult && this.analysisResult.AdjustMajors && this.analysisResult.AdjustMajors.length > 0) {
        this.filter3 = '';
        var model = {
          AdjustMajorCodes: this.analysisResult.AdjustMajors.map(p => p.MajorCode),
          AdjustPartitionDegreeTypes: this.analysisResult.AdjustPartitionDegreeTypes,
        };
        return this.$axios
          .post(Config.smartAdjust.filter.conditionList, model)
          .then((res) => {
            this.analysisFilter = res.data.Data;
            if (this.filterBelongCategoryList.length > 0) {
              this.filter3 = this.filterBelongCategoryList[0];
            }
            if (this.filterFirstDisciplineList.length > 0) {
              this.filter4 = [this.filterFirstDisciplineList[0]];
            }
          })
          .catch((error) => {
            this.$message.error(error.message);
          });
      }
    },
    //获取推荐专业数量
    getRecommendMajorCount() {
      if (this.analysisResult && this.analysisResult.AdjustMajors && this.analysisResult.AdjustMajors.length > 0) {
        var model = {
          UniversityCode: this.analysisResult.UniversityCode,
          CollegeCode: this.analysisResult.CollegeCode,
          MajorCode: this.analysisResult.MajorCode,
          TotalScore: this.totalScore,
          AdjustMajorCodes: this.analysisResult.AdjustMajors.map(p => p.MajorCode),
          AdjustPartitionDegreeTypes: this.analysisResult.AdjustPartitionDegreeTypes,
          MajorLearningWays: this.filter1,
          MajorDegreeTypes: this.filter2,
          MajorBelongcategory: this.filter3,
          MajorBelongFirstDisciplines: this.filter4,
          MajorCodes: this.filter5,
          UniversityProvinces: this.filter6,
          UniversityCharacteristic: this.filter7,
        };
        this.$axios
          .post(Config.smartAdjust.majorCount, model)
          .then((res) => {
            let data = res.data.Data;
            this.countAll = data.CountAll;
            this.countSafer = data.CountSafer;
            this.countStrike = data.CountStrike;
            this.countOther = data.CountOther;
          })
          .catch((error) => {
            this.$message.error(error.message);
          });
      }
    },
    //获取推荐专业列表
    getRecommendMajorList() {
      if (this.analysisResult && this.analysisResult.AdjustMajors && this.analysisResult.AdjustMajors.length > 0) {
        var model = {
          SearchType: this.active,
          UniversityCode: this.analysisResult.UniversityCode,
          CollegeCode: this.analysisResult.CollegeCode,
          MajorCode: this.analysisResult.MajorCode,
          PoliticalScore: this.analysisResult.PoliticalScore,
          EnglishScore: this.analysisResult.EnglishScore,
          MajorScore1: this.analysisResult.MajorScore1,
          MajorScore2: this.analysisResult.MajorScore2,
          AdjustMajorCodes: this.analysisResult.AdjustMajors.map(p => p.MajorCode),
          AdjustPartitionDegreeTypes: this.analysisResult.AdjustPartitionDegreeTypes,
          MajorLearningWays: this.filter1,
          MajorDegreeTypes: this.filter2,
          MajorBelongcategory: this.filter3,
          MajorBelongFirstDisciplines: this.filter4,
          MajorCodes: this.filter5,
          UniversityProvinces: this.filter6,
          UniversityCharacteristic: this.filter7,
          TotalScore: this.totalScore,
          PageIndex: this.pageIndex,
          PageSize: this.pageSize,
        };
        this.$axios
          .post(Config.smartAdjust.majorList, model)
          .then((res) => {
            let data = res.data.Data;
            this.totalCount = data.TotalCount;
            this.admitInfos = data.RecommendMajorInfos;
          })
          .catch((error) => {
            this.$message.error(error.message);
          });
      }
    },
    //获取报录数据对比列表
    getAdmitCompareInfos() {
      if (!this.$store.getters.isLogin) {
        return
      }
      var model = {};
      this.$axios
        .post(Config.smartAdjust.compare.list, model)
        .then((res) => {
          this.admitCompares = res.data.Data.AdmitCompareInfos;
        })
        .catch((error) => {
          this.$message.error(error.message);
        });
    },
    //添加报录数据对比记录
    addAdmitCompareInfo(admit) {
      var model = {
        UniversityCode: admit.UniversityCode,
        CollegeCode: admit.CollegeCode,
        MajorCode: admit.MajorCode,
        MajorLearningWay: admit.MajorLearningWay,
        ProxyCode: this.$store.state.proxyCode,
      };
      return this.$axios
        .post(Config.smartAdjust.compare.add, model)
        .then((res) => {

        })
        .catch((error) => {
          this.$message.error(error.message);
        });
    },
    //删除报录数据对比记录
    delAdmitCompareInfo(compareGuid) {
      var model = {
        CompareGuid: compareGuid,
      };
      return this.$axios
        .post(Config.smartAdjust.compare.delete, model)
        .then((res) => {

        })
        .catch((error) => {
          this.$message.error(error.message);
        });
    },
    //自动加载数据
    autoLoadData() {
      if (!Tools.isEmpty(this.formData.universityCode) && !Tools.isEmpty(this.formData.collegeCode) && !Tools.isEmpty(this.formData.majorCode)) {
        this.$refs.smartAdjustForm.autoLoadData();
        this.onSubmitAnalyze();
      }
    },
    //添加访问商品记录
    addVisitProductRecord() {
      Manager.addVisitProductRecord("智慧调剂");
    },
    //点击发送验证码
    onSendCaptcha() {
      if (!Tools.checkPhone(this.formData.studentPhone)) {
        this.$message.warning("请输入正确的手机号码。");
      } else {
        //发送验证码
        var model = {
          Phone: this.formData.studentPhone,
        };
        this.$axios
          .post(Config.sms.sendCommonMessage, model)
          .then((res) => {
            this.btnSendDisabled = true;
            this.$message.success("验证码发送成功。");
          })
          .catch((error) => {
            this.$message.error(error.message);
          });
      }
    },
    //点击开始调剂分析
    onSubmitAnalyze() {
      this.$refs.smartAdjustForm.validate().then(async () => {
        await this.getAnalysisResult();
        await this.getFilterConditions();
        this.getRecommendMajorList();
        this.getRecommendMajorCount();
      }).catch(() => {

      })
    },
    //弹窗填写学生报考信息
    onShowStudent() {
      this.studentDialog = true;
    },
    //生成调剂报告
    onSubmitReport(formData) {
      if (this.analysisResult) {
        if (!this.$store.getters.isLogin) {
          this.$loginDialog.show(true);
        } else {
          this.studentDialog = false;
          //添加报告生成任务
          var model = {
            ProxyCode: this.$store.state.proxyCode,
            UniversityCode: this.analysisResult.UniversityCode,
            CollegeCode: this.analysisResult.CollegeCode,
            MajorCode: this.analysisResult.MajorCode,
            PoliticalScore: this.analysisResult.PoliticalScore,
            EnglishScore: this.analysisResult.EnglishScore,
            MajorScore1: this.analysisResult.MajorScore1,
            MajorScore2: this.analysisResult.MajorScore2,
            StudentName: formData.studentName,
            StudentPhone: formData.studentPhone,
            FilterMajorLearningWays: this.filter1,
            FilterMajorDegreeTypes: this.filter2,
            FilterMajorBelongcategory: this.filter3,
            FilterMajorBelongFirstDisciplines: this.filter4,
            FilterMajorCodes: this.filter5,
            FilterUniversityProvinces: this.filter6,
            FilterUniversityCharacteristic: this.filter7,
          };
          this.$axios
            .post(Config.smartAdjust.reportGenerate, model)
            .then((res) => {
              var result = res.data.Data;
              if (result.ReportState == -1) {
                //跳转订单提交
                this.$router.push({
                  name: "SubmitMemberOrder",
                  params: {
                    proxyCode: this.$store.state.proxyCode
                  }
                });
              } else {
                if (this.countAll > 1000) {
                  this.$alert('调剂报告仅支持小于1000条数据的报告生成，请优化减少调剂院校总数量。', '提示');
                } else {
                  if (result.ReportState == 0) {
                    this.$alert('报告正在生成中，请到我的资料里面查看生成进度。', '提示');
                  } else {
                    this.$alert('报告已生成完毕，请到我的资料里面下载。', '提示');
                  }
                }
              }
            })
            .catch((error) => {
              this.$message.error(error.message);
            });
        }
      }
    },
    //取消生成报告
    onCancelReport() {
      this.studentDialog = false;
    },
    //点击解锁填报调剂去向
    onFormUnlockClick() {
      this.$router.push({
        name: 'SubmitMemberOrder',
        params: {
          proxyCode: this.$store.state.proxyCode
        }
      });
    },
    //点击解锁推荐调剂数据
    onItemUnlockClick(admit) {
      this.$router.push({
        name: "SubmitMemberOrder",
        params: {
          proxyCode: this.$store.state.proxyCode
        }
      });
    },
    //点击调剂
    onItemAdjustClick(admit) {
      this.$router.push({
        name: "Adjust1",
        params: {
          proxyCode: this.$store.state.proxyCode,
          year: admit.MajorEnrollmentYear,
          universityCode: admit.UniversityCode,
          collegeCode: admit.CollegeCode,
          majorCode: admit.MajorCode,
          majorLearnway: admit.MajorLearningWay,
        }
      });
    },
    //点击专业项
    onItemClick(admit) {
      if (this.isMembership) {
        this.$router.push({
          name: 'Admit1',
          params: {
            proxyCode: this.$store.state.proxyCode,
            universityCode: admit.UniversityCode,
            collegeCode: admit.CollegeCode,
            majorCode: admit.MajorCode,
            majorLearnway: admit.MajorLearningWay
          }
        })
      }
    },
    //点击过滤
    onFilterClick() {
      this.pageIndex = 1;
      this.getRecommendMajorList();
      this.getRecommendMajorCount();
    },
    //选项卡切换
    onTabChange() {
      this.pageIndex = 1;
      this.getRecommendMajorList();
    },
    //点击分页
    onCurrentChange() {
      this.getRecommendMajorList();
    },
    //点击加入对比
    async onAddCompareClick(admit) {
      this.loading = true;
      await this.addAdmitCompareInfo(admit);
      await this.getAdmitCompareInfos();
      this.loading = false;
    },
    //移除对比
    async onDelCompareClick(index) {
      this.loading = true;
      await this.delAdmitCompareInfo(this.admitCompares[index].CompareGuid);
      await this.getAdmitCompareInfos();
      this.loading = false;
    },
  },
  mounted() {
    this.getRoutParams();
    this.autoLoadData();
    this.getIsMembership();
    this.getAdmitCompareInfos();
    this.addVisitProductRecord();
  },
};
</script>

<style scoped lang="less">
.container-box {
  display: flex;
  flex-direction: row;
}

.box-left {
  flex: 1;
  overflow: hidden;
}

.box-right {
  width: 300px;
  margin: 0 0 0 50px;
}

.tabs-box {
  position: relative;
}

.report-button {
  position: absolute;
  right: 0px;
  top: 0px;
}

.school-filter {
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #e6e6e6;
}

.school-tabs {
  margin-top: 10px;
}

.school-list {
  min-height: 100px;
  padding: 20px;
  border-left: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
}

.dark-border {
  border: 1px solid #e6e6e6;
}

.bottom-line {
  border-top: 1px solid #e6e6e6;
  margin: 10px 0;
}

.form-box {
  padding: 0px 10px;

  .title {
    font-size: 14px;
    font-weight: 700;
    margin: 10px 0;
  }

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
  }
}

.pagination-box {
  text-align: center;
  margin: 10px;
}

.guide-box {
  margin: 20px 0;
  font-size: 18px;

  .numBox {
    display: inline;
    letter-spacing: 5px;

    .num1 {
      color: #ff6602;
      font-size: 20px;
      font-weight: 700;
    }

    .num2 {
      color: gray;
      font-size: 16px;
    }
  }

  .txt {
    margin-left: 10px;
  }
}

.result-box {
  padding: 10px;

  .title {
    color: #37373d;
    font-size: 15px;
    font-weight: normal;
  }

  .row_box {
    padding: 5px 0;
  }

  .txt1 {
    width: 200px;
    text-align: right;
  }

  .txt2 {
    flex: 1;
  }

  .txt {
    color: gray;
    font-size: 14px;
    margin: 5px;
    margin-left: 5px;
    word-spacing: 10px;
  }

  .major_box {
    margin-bottom: 10px;
  }

  .major2_box {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .major1 {
    color: #ff6602;
    font-size: 14px;
    margin-left: 10px;
    margin-bottom: 10px;
  }

  .major2 {
    color: gray;
    font-size: 14px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .adjust-title {
    color: rgb(39, 39, 39);
    font-size: 14px;
    margin-bottom: 10px;
  }

  .adjust-text {
    display: inline-block;
    white-space: nowrap;
    color: gray;
    font-size: 12px;
    margin: 5px 20px 5px 0;
  }

  .adjust-img {
    cursor: pointer;
    min-height: 50px;
  }

  .adjust-lock {
    cursor: pointer;
    height: 15px;
    margin-left: 10px;
  }

  .adjust-tip {
    color: red;
    font-size: 13px;
  }
}

.content-tabs {
  width: 100%;
}

.content-pane {
  min-height: 100px;
  padding: 20px;
  border-left: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
}

.content-admit {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 10px 0;

  .content-admit-item {
    width: 50%;
    margin: 10px 0;
    display: flex;
    align-items: center;

    .content-admit-item-title {
      display: inline-block;
      color: #555;
      font-size: 14px;
      font-weight: 700;
    }

    .content-admit-item-text {
      color: #555;
      font-size: 14px;
    }
  }

  .content-admit-img {
    cursor: pointer;
    min-height: 50px;
  }
}

.content-tip {
  color: red;
  font-size: 13px;
}

.content-adjust-title {
  color: rgb(39, 39, 39);
  font-size: 14px;
  margin-bottom: 10px;
}

.content-adjust-text {
  display: inline-block;
  white-space: nowrap;
  color: gray;
  font-size: 12px;
  margin: 5px 20px 5px 0;
}

.empty-txt {
  padding: 10px;
  color: gray;
  font-size: 14px;
}

.el-icon-close {
  cursor: pointer;
  margin-left: 10px;
}

.el-icon-close:hover {
  color: #ff6602;
}

::v-deep .el-tabs__header {
  margin: 0px;
}

::v-deep .el-tabs--card>.el-tabs__header .el-tabs__nav {
  border-radius: 0px;
}
</style>