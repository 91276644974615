<template>
  <el-form ref="form" :model="formData" :rules="formRules" :inline="true" label-suffix="" size="small">
    <el-row class="title">一志愿报考信息</el-row>
    <el-row :gutter="10">
      <el-col :span="6">
        <el-form-item prop="universityCode">
          <search-select v-model="universityItem" value-key="Key" placeholder="学校" @change="onSelectChange1">
            <search-option v-for="item in universityInfos" :value="item" :label="item.UniversityName"></search-option>
          </search-select>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item prop="collegeCode">
          <search-select v-model="collegeItem" value-key="Key" placeholder="学院" @change="onSelectChange2">
            <search-option v-for="item in collegeInfos" :value="item" :label="item.CollegeCode + item.CollegeName"></search-option>
          </search-select>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item prop="majorCode">
          <search-select v-model="majorItem" value-key="Key" placeholder="专业">
            <search-option v-for="item in majorInfos" :value="item" :label="item.MajorCode + item.MajorName">
              <template>
                {{ `${item.MajorCode}${item.MajorName}/${item.CollegeCode}${item.CollegeName}` }}
              </template>
            </search-option>
          </search-select>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item prop="majorLearningWay">
          <el-select v-model="formData.majorLearningWay" placeholder="学习方式" filterable>
            <el-option label="全日制" value="全日制"></el-option>
            <el-option label="非全日制" value="非全日制"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row class="title">初试成绩</el-row>
    <el-row :gutter="0">
      <el-col :span="5">
        <el-form-item label="政治" prop="politicalScore">
          <el-input-number v-model="formData.politicalScore" :min="0" :max="100" type="number" placeholder="政治" :controls="false" style="width: 80px;"></el-input-number>
        </el-form-item>
      </el-col>
      <el-col :span="5">
        <el-form-item label="外语" prop="englishScore">
          <el-input-number v-model="formData.englishScore" :min="0" :max="100" type="number" placeholder="外语" :controls="false" style="width: 80px;"></el-input-number>
        </el-form-item>
      </el-col>
      <el-col :span="5">
        <el-form-item label="专业一" prop="majorScore1">
          <el-input-number v-model="formData.majorScore1" :min="0" :max="300" type="number" placeholder="专业一" :controls="false" style="width: 80px;"></el-input-number>
        </el-form-item>
      </el-col>
      <el-col :span="5">
        <el-form-item label="专业二" prop="majorScore2">
          <el-input-number v-model="formData.majorScore2" :min="0" :max="300" type="number" placeholder="专业二" :controls="false" style="width: 80px;"></el-input-number>
        </el-form-item>
      </el-col>
      <el-col :span="4">
        <el-form-item label="总分">
          <el-input-number disabled :value="totalScore" type="number" placeholder="总分" :controls="false" style="width: 80px;"></el-input-number>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import RadioItem from "@/components/PC/RadioItem";
import RadioItemGroup from "@/components/PC/RadioItemGroup";
import SearchSelect from "@/components/PC/SearchSelect";
import SearchOption from "@/components/PC/SearchOption";
import Config from "@/api/service";
export default {
  name: "SmartAdjustForm",
  components: {
    RadioItem,
    RadioItemGroup,
    SearchSelect,
    SearchOption,
  },
  props: {
    formData: {
      type: Object,
      default: {
        universityCode: null,
        collegeCode: null,
        majorCode: null,
        majorLearningWay: null,
        politicalScore: 0,
        englishScore: 0,
        majorScore1: 0,
        majorScore2: 0,
      },
    },
  },
  data() {
    return {
      formRules: {
        universityCode: [
          { required: true, message: '请选择学校', trigger: 'blur' }
        ],
        collegeCode: [
          { required: true, message: '请选择学院', trigger: 'blur' }
        ],
        majorCode: [
          { required: true, message: '请选择专业', trigger: 'blur' }
        ],
        majorLearningWay: [
          { required: true, message: '请选择学习方式', trigger: 'blur' }
        ],
        politicalScore: [
          { required: true, message: '请输入政治分数', trigger: 'blur' }
        ],
        englishScore: [
          { required: true, message: '请输入外语分数', trigger: 'blur' }
        ],
        majorScore1: [
          { required: true, message: '请输入专业一分数', trigger: 'blur' }
        ],
        majorScore2: [
          { required: true, message: '请输入专业二分数', trigger: 'blur' }
        ],
      },
      universityInfos: [],
      collegeInfos: [],
      majorInfos: [],
    };
  },
  computed: {
    universityItem: {
      get: function () {
        return this.universityInfos.find(item => item.UniversityCode == this.formData.universityCode)
      },
      set: function (item) {
        this.formData.universityCode = item.UniversityCode;
      }
    },
    collegeItem: {
      get: function () {
        return this.collegeInfos.find(item => item.UniversityCode == this.formData.universityCode && item.CollegeCode == this.formData.collegeCode)
      },
      set: function (item) {
        this.formData.collegeCode = item.CollegeCode;
      }
    },
    majorItem: {
      get: function () {
        return this.majorInfos.find(item => item.UniversityCode == this.formData.universityCode && item.CollegeCode == this.formData.collegeCode && item.MajorCode == this.formData.majorCode)
      },
      set: function (item) {
        this.formData.collegeCode = item.CollegeCode;
        this.formData.majorCode = item.MajorCode;
      }
    },
    totalScore() {
      var total = Number(this.formData.politicalScore) + Number(this.formData.englishScore) + Number(this.formData.majorScore1) + Number(this.formData.majorScore2);
      if (isNaN(total)) {
        return 0;
      } else {
        return total;
      }
    }
  },
  methods: {
    //自动加载数据
    autoLoadData() {
      this.getAllCollegeList();
      this.getAllMajorList();
    },
    //重置数据
    resetData() {
      this.formData.universityCode = null;
      this.formData.collegeCode = null;
      this.formData.majorCode = null;
      this.formData.majorLearningWay = '全日制';
      this.formData.politicalScore = 0;
      this.formData.englishScore = 0;
      this.formData.majorScore1 = 0;
      this.formData.majorScore2 = 0;
      this.universityInfos = [];
      this.collegeInfos = [];
      this.majorInfos = [];
      this.getAllUniversityList();
    },
    //验证数据
    validate() {
      return new Promise((resolve, reject) => {
        this.$refs.form.validate((valid) => {
          if (valid) {
            resolve(true);
          } else {
            reject(false);
          }
        });
      });
    },
    //获取所有大学列表
    getAllUniversityList() {
      setTimeout(() => {
        var model = {};
        this.$axios
          .post(Config.smartAdjust.form.schoolList, model)
          .then((res) => {
            //列表
            this.universityInfos = res.data.Data.UniversityInfos;
            //设置Key
            this.universityInfos.forEach(item => {
              item.Key = item.UniversityCode;
            });
          })
          .catch((error) => {
            this.$message.error(error.message);
          });
      }, 0)
    },
    //获取所有学院列表
    getAllCollegeList() {
      setTimeout(() => {
        var model = {
          UniversityCode: this.formData.universityCode
        };
        this.$axios
          .post(Config.smartAdjust.form.collegeList, model)
          .then((res) => {
            //列表
            this.collegeInfos = res.data.Data.CollegeInfos;
            //设置Key
            this.collegeInfos.forEach(item => {
              item.Key = item.UniversityCode + item.CollegeCode;
            });
          })
          .catch((error) => {
            this.$message.error(error.message);
          });
      }, 0)
    },
    //获取所有专业列表
    getAllMajorList() {
      setTimeout(() => {
        var model = {
          UniversityCode: this.formData.universityCode,
          CollegeCode: this.formData.collegeCode
        };
        this.$axios
          .post(Config.smartAdjust.form.majorList, model)
          .then((res) => {
            //列表
            this.majorInfos = res.data.Data.MajorInfos;
            //设置Key
            this.majorInfos.forEach(item => {
              item.Key = item.UniversityCode + item.CollegeCode + item.MajorCode;
            });
          })
          .catch((error) => {
            this.$message.error(error.message);
          });
      }, 0)
    },
    //选择大学改变
    onSelectChange1() {
      this.formData.collegeCode = null;
      this.formData.majorCode = null;
      this.collegeInfos = [];
      this.majorInfos = [];
      this.getAllCollegeList();
      this.getAllMajorList();
    },
    //选择学院改变
    onSelectChange2() {
      this.formData.majorCode = null;
      this.majorInfos = [];
      this.getAllMajorList();
    }
  },
  mounted() {
    this.resetData();
  }
};
</script>

<style lang="less" scoped>
.title {
  font-size: 14px;
  font-weight: 700;
  margin: 10px 0;
}
</style>
