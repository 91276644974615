<template>
  <div class="box">
    <div class="line1">
      <div v-if="isUnlocked" class="col1">
        <span class="title">{{ school }}-{{ major }}</span>
      </div>
      <div v-else class="col1">
        <span class="title">
          <img :src="require('@/assets/images/comm/lock.png')" class="lock-img" />
          <span> - </span>
          <span>{{ major }}</span>
        </span>
      </div>
      <div class="col2">
        <img src="@/assets/images/pc/position.png" class="img-position" />
        <span class="province">{{ province }}-{{ partition }}区</span>
      </div>
      <div class="col3">
        {{ characteristic.replaceAll(' ', ' 丨 ') }}
      </div>
      <div class="col4">
        <el-button v-if="!isUnlocked" type="primary" size="mini" @click="onUnlockClick">解锁</el-button>
        <el-button v-if="isAdjust && isUnlocked" type="primary" size="mini" @click.stop="onAdjustClick">24调剂</el-button>
        <el-button v-if="compareVisible" type="primary" size="mini" plain @click.stop="onCompareClick">加入对比</el-button>
        <el-button v-if="isUnlocked" type="primary" size="mini" @click.stop="onItemClick">查看详情</el-button>
      </div>
    </div>
    <div class="line2">
      <span class="text">专业代码：</span>
      <span class="text">{{ code }}</span>
      <span class="verticalbar"></span>
      <span class="text">学习方式：</span>
      <span class="text">{{ learnway }}</span>
      <span class="verticalbar"></span>
      <span class="text">招生人数：</span>
      <span class="text">--</span>
      <span class="verticalbar"></span>
      <span class="text">所属学院：</span>
      <span v-if="isUnlocked" class="text-ellipsis">{{ college }}</span>
      <el-image v-else class="lock-img" :src="require('@/assets/images/comm/lock.png')"></el-image>
    </div>
    <div v-if="admitInfoList.length == 1" class="line3">
      <span class="text">{{ admitInfoList[0].AdmitYear }}年</span>
      <span class="verticalbar"></span>
      <span class="text">总录取人数：</span>
      <span class="text">{{ admitInfoList[0].AdmitTotalNumber || '--' }}</span>
      <span class="verticalbar"></span>
      <span class="text">一志愿：</span>
      <span class="text">{{ admitInfoList[0].AdmitVolunteerNumber || '--' }}</span>
      <span class="verticalbar"></span>
      <span class="text">调剂：</span>
      <span class="text">{{ admitInfoList[0].AdmitAdjustedNumber || '--' }}</span>
      <span class="verticalbar"></span>
      <span class="text">一志愿最低分：</span>
      <span class="text">{{ admitInfoList[0].AdmitVolunteerMinScore || '--' }}</span>
      <span class="verticalbar"></span>
      <span class="text">调剂最低分：</span>
      <span class="text">{{ admitInfoList[0].AdmitAdjustMinScore || '--' }}</span>
      <span class="verticalbar"></span>
      <span class="text">目标分数：</span>
      <span class="text">{{ admitInfoList[0].AdmitTargetScore || '--' }}</span>
    </div>
    <el-collapse v-else-if="admitInfoList.length > 1" class="collapse">
      <el-collapse-item>
        <template v-for="(item, index) in admitInfoList" :slot="index == 0 ? 'title' : 'default'">
          <div class="line3">
            <span class="text">{{ item.AdmitYear }}年</span>
            <span class="verticalbar"></span>
            <span class="text">总录取人数：</span>
            <span class="text">{{ item.AdmitTotalNumber || '--' }}</span>
            <span class="verticalbar"></span>
            <span class="text">一志愿：</span>
            <span class="text">{{ item.AdmitVolunteerNumber || '--' }}</span>
            <span class="verticalbar"></span>
            <span class="text">调剂：</span>
            <span class="text">{{ item.AdmitAdjustedNumber || '--' }}</span>
            <span class="verticalbar"></span>
            <span class="text">一志愿最低分：</span>
            <span class="text">{{ item.AdmitVolunteerMinScore || '--' }}</span>
            <span class="verticalbar"></span>
            <span class="text">调剂最低分：</span>
            <span class="text">{{ item.AdmitAdjustMinScore || '--' }}</span>
            <span class="verticalbar"></span>
            <span class="text">目标分数：</span>
            <span class="text">{{ item.AdmitTargetScore || '--' }}</span>
          </div>
        </template>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
export default {
  name: "SmartAdjustItem",
  components: {

  },
  props: {
    isUnlocked: {
      type: Boolean,
      default: false,
    },
    isAdjust: {
      type: Boolean,
      default: false,
    },
    compareVisible: {
      type: Boolean,
      default: false,
    },
    img: {
      type: String,
      default: '@/assets/images/pc/school.png',
    },
    school: {
      type: String,
      default: '北京大学',
    },
    major: {
      type: String,
      default: '科学技术',
    },
    province: {
      type: String,
      default: '北京',
    },
    partition: {
      type: String,
      default: 'A区',
    },
    characteristic: {
      type: String,
      default: '985 211',
    },
    year: {
      type: String,
      default: '2023',
    },
    code: {
      type: String,
      default: '100100',
    },
    learnway: {
      type: String,
      default: '全日制',
    },
    college: {
      type: String,
      default: '科学技术学院',
    },
    admitInfoList: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {};
  },
  methods: {
    onUnlockClick() {
      this.$emit("unlock-click");
    },
    onItemClick() {
      this.$emit("item-click");
    },
    onAdjustClick() {
      this.$emit("adjust-click");
    },
    onCompareClick() {
      this.$emit("compare-click");
    },
  },
};
</script>

<style scoped lang="less">
.box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.img-school {
  width: 60px;
  height: 60px;
}

.img-position {
  width: 16px;
  height: 16px;
  margin: 0 5px;
}

.line1 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;

  .col1 {
    display: flex;
    align-items: center;
    margin-right: 20px;
  }

  .col2 {
    display: flex;
    align-items: center;
    margin-right: 20px;
  }

  .col3 {
    flex: 1;
    word-spacing: 2px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 14px;
    color: #9f9f9f;
    margin-right: 20px;
  }

  .col4 {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.line2 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #555;
  font-size: 14px;
  margin: 5px 0;
}

.line3 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: orangered;
  font-size: 14px;
  margin: 5px 0;
}

.title {
  font-size: 18px;
  font-weight: 700;
  white-space: pre-wrap;
}

.province {
  font-size: 14px;
  color: #888;
}

.text {
  white-space: nowrap;
}

.text-ellipsis {
  font-size: 14px;
  color: #555;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.verticalbar {
  border-left: 1px solid;
  content: "";
  display: inline-block;
  width: 1px;
  height: 13px;
  margin: 0 10px;
  top: 2px;
}

.lock-img {
  width: 12px;
  height: 14px;
}

.collapse {
  width: 100%;
}

::v-deep .el-collapse {
  border: 0px !important;
}

::v-deep .el-collapse-item__header {
  border: 0px !important;
  height: auto !important;
  line-height: normal !important;
}

::v-deep .el-collapse-item__wrap {
  border: 0px !important;
}

::v-deep .el-collapse-item__content {
  padding-bottom: 0px !important;
}
</style>
