<template>
  <div>
    <div class="box flex" v-if="learningWayList.length > 0">
      <div class="text1">学习方式</div>
      <img src="@/assets/images/pc/right.png" class="img">
      <check-item-group class="flex fill" v-model="value1">
        <radio-item label="全部" :checked="value1.length == 0" @click="onClear1"></radio-item>
        <check-item v-for="(item, index) in learningWayList" :key="index" :label="item" :name="item" @click="onClick1"></check-item>
      </check-item-group>
    </div>
    <div class="margin-column"></div>
    <div v-if="degreeTypeList.length > 0" class="box flex">
      <div class="text1">学位类别</div>
      <img src="@/assets/images/pc/right.png" class="img">
      <check-item-group class="flex fill" v-model="value2">
        <radio-item label="全部" :checked="value2.length == 0" @click="onClear2"></radio-item>
        <check-item v-for="(item, index) in degreeTypeList" :key="index" :label="item" :name="item" @click="onClick2"></check-item>
      </check-item-group>
    </div>
    <div class="margin-column"></div>
    <div v-if="belongCategoryList.length > 0" class="box flex" style="display: none;">
      <div class="text1">所属门类</div>
      <img src="@/assets/images/pc/right.png" class="img">
      <radio-item-group class="flex fill" v-model="value3">
        <div class="fill">
          <radio-item label="全部" name="" @click="onClear3"></radio-item>
          <radio-item v-for="(item, index) in belongCategoryList" :key="index" :label="item" :name="item" @click="onClick3"></radio-item>
        </div>
      </radio-item-group>
    </div>
    <div class="margin-column"></div>
    <div v-if="firstDisciplineList.length > 0" class="box flex">
      <div class="text1">学科类别</div>
      <img src="@/assets/images/pc/right.png" class="img">
      <check-item-group class="flex fill" v-model="value4">
        <radio-item label="全部" :checked="value4.length == 0" @click="onClear4"></radio-item>
        <div class="fill">
          <check-item v-for="(item, index) in firstDisciplineList" :key="index" :label="item" :name="item" @click="onClick4"></check-item>
        </div>
      </check-item-group>
    </div>
    <div class="margin-column"></div>
    <div v-if="adjustMajorList.length > 0" class="box flex">
      <div class="text1">调剂专业</div>
      <img src="@/assets/images/pc/right.png" class="img">
      <check-item-group class="flex fill" v-model="value5">
        <radio-item label="全部" :checked="value5.length == 0" @click="onClear5"></radio-item>
        <div class="fill">
          <check-item v-for="(item, index) in adjustMajorList" :key="index" :label="item.MajorCode + item.MajorName" :name="item.MajorCode" @click="onClick5"></check-item>
        </div>
      </check-item-group>
    </div>
    <div class="margin-column"></div>
    <div class="box flex" v-if="aProvinceList.length > 0 || bProvinceList.length > 0">
      <div class="text1">院校位置</div>
      <img src="@/assets/images/pc/right.png" class="img">
      <check-item-group class="flex fill" v-model="value6">
        <radio-item label="全部" :checked="value6.length == 0" @click="onClear6"></radio-item>
        <div class="fill">
          <div v-if="aProvinceList.length > 0" class="flex">
            <div class="text2">A区</div>
            <div class="fill">
              <check-item v-for="(item, index) in aProvinceList" :key="index" :label="item" :name="item" @click="onClick6"></check-item>
            </div>
          </div>
          <div v-if="bProvinceList.length > 0" class="flex">
            <div class="text2">B区</div>
            <div class="fill">
              <check-item v-for="(item, index) in bProvinceList" :key="index" :label="item" :name="item" @click="onClick6"></check-item>
            </div>
          </div>
        </div>
      </check-item-group>
    </div>
    <div class="margin-column"></div>
    <div class="box flex">
      <div class="text1">院校特色</div>
      <img src="@/assets/images/pc/right.png" class="img">
      <radio-item-group class="flex fill" v-model="value7">
        <radio-item label="全部" name="" @click="onClear7"></radio-item>
        <div class="fill">
          <radio-item label="985" name="985" @click="onClick7"></radio-item>
          <radio-item label="211" name="211" @click="onClick7"></radio-item>
          <radio-item label="双一流" name="双一流" @click="onClick7"></radio-item>
          <radio-item label="自划线" name="自划线" @click="onClick7"></radio-item>
          <radio-item label="高等院校" name="高等院校" @click="onClick7"></radio-item>
          <radio-item label="科研院所" name="科研院所" @click="onClick7"></radio-item>
        </div>
      </radio-item-group>
    </div>
  </div>
</template>

<script>
import RadioItem from "@/components/PC/RadioItem";
import RadioItemGroup from "@/components/PC/RadioItemGroup";
import CheckItem from "@/components/PC/CheckItem";
import CheckItemGroup from "@/components/PC/CheckItemGroup";
import Config from "@/api/service";
export default {
  name: "SmartAdjustFilter",
  components: {
    RadioItem,
    RadioItemGroup,
    CheckItem,
    CheckItemGroup,
  },
  props: {
    learningWayList: {
      type: Array,
      default: [],
    },
    degreeTypeList: {
      type: Array,
      default: [],
    },
    belongCategoryList: {
      type: Array,
      default: [],
    },
    firstDisciplineList: {
      type: Array,
      default: [],
    },
    adjustMajorList: {
      type: Array,
      default: [],
    },
    aProvinceList: {
      type: Array,
      default: [],
    },
    bProvinceList: {
      type: Array,
      default: [],
    },
    filter1: {
      type: Array,
      default: [],
    },
    filter2: {
      type: Array,
      default: [],
    },
    filter3: {
      type: String,
      default: '',
    },
    filter4: {
      type: Array,
      default: [],
    },
    filter5: {
      type: Array,
      default: [],
    },
    filter6: {
      type: Array,
      default: [],
    },
    filter7: {
      type: String,
      default: '',
    },
  },
  computed: {
    value1: {
      get: function () {
        return this.filter1;
      },
      set: function (val) {
        this.$emit('update:filter1', val);
      }
    },
    value2: {
      get: function () {
        return this.filter2;
      },
      set: function (val) {
        this.$emit('update:filter2', val);
      }
    },
    value3: {
      get: function () {
        return this.filter3;
      },
      set: function (val) {
        this.$emit('update:filter3', val);
      }
    },
    value4: {
      get: function () {
        return this.filter4;
      },
      set: function (val) {
        this.$emit('update:filter4', val);
      }
    },
    value5: {
      get: function () {
        return this.filter5;
      },
      set: function (val) {
        this.$emit('update:filter5', val);
      }
    },
    value6: {
      get: function () {
        return this.filter6;
      },
      set: function (val) {
        this.$emit('update:filter6', val);
      }
    },
    value7: {
      get: function () {
        return this.filter7;
      },
      set: function (val) {
        this.$emit('update:filter7', val);
      }
    },
  },
  data() {
    return {
    };
  },
  methods: {
    onClear1() {
      this.value1 = [];
      this.onClick1();
    },
    onClear2() {
      this.value2 = [];
      this.onClick2();
    },
    onClear3() {
      this.value3 = '';
      this.onClick3();
    },
    onClear4() {
      this.value4 = [];
      this.onClick4();
    },
    onClear5() {
      this.value5 = [];
      this.onClick5();
    },
    onClear6() {
      this.value6 = [];
      this.onClick6();
    },
    onClear7() {
      this.value7 = '';
      this.onClick7();
    },
    onClick1() {
      this.value2 = [];
      this.value4 = [];
      this.value5 = [];
      this.value6 = [];
      this.value7 = '';
      this.$emit('filter');
    },
    onClick2() {
      this.value4 = [];
      this.value5 = [];
      this.value6 = [];
      this.value7 = '';
      this.$emit('filter');
    },
    onClick3() {
      this.value4 = [];
      this.value5 = [];
      this.value6 = [];
      this.value7 = '';
      this.$emit('filter');
    },
    onClick4() {
      this.value5 = [];
      this.value6 = [];
      this.value7 = '';
      this.$emit('filter');
    },
    onClick5() {
      this.value6 = [];
      this.value7 = '';
      this.$emit('filter');
    },
    onClick6() {
      this.value7 = '';
      this.$emit('filter');
    },
    onClick7() {
      this.$emit('filter');
    },
  },
  mounted() {

  }
};
</script>

<style lang="less" scoped>
.box {
  font-size: 14px;
}

.img {
  width: 15px;
  height: 15px;
  padding: 10px 0;
  margin-top: 2px;
}

.flex {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
}

.fill {
  flex: 1;
}

.text1 {
  padding: 10px 10px 10px 0;
  color: black;
  word-spacing: 24px;
}

.text2 {
  width: 30px;
  padding: 10px;
  color: gray;
}

.search {
  width: 300px;
  margin-left: 10px;
  margin-top: 5px;
}

.margin-column {
  margin: 20px 0;
}
</style>
