<template>
  <div>
    <el-form ref="form" :model="formData" :rules="formRules" :inline="false" label-suffix="" size="small" class="form-box">
      <el-form-item label="姓名" prop="studentName">
        <el-input v-model="formData.studentName" placeholder="姓名"></el-input>
      </el-form-item>
      <el-form-item label="电话" prop="studentPhone">
        <el-input v-model="formData.studentPhone" placeholder="电话"></el-input>
      </el-form-item>
      <el-form-item class="button">
        <el-button type="primary" @click="onSubmit">开始生成报告</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import Config from "@/api/service";

export default {
  name: "StudentDialog2",
  components: {
  },
  props: {
    studentName: {
      type: String,
      default: '',
    },
    studentPhone: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      formData: {
        studentName: null,
        studentPhone: null,
      },
      formRules: {
        studentName: [
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ],
        studentPhone: [
          { required: true, message: '请输入电话', trigger: 'blur' },
          {
            required: true,
            pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
            message: '请输入正确的手机号码',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  methods: {//提交
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$emit('submit', this.formData);
        }
      });
    },
    //取消
    onCancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<style scoped lang="less">
.form-box {
  padding: 0px 10px;

  .title {
    font-size: 14px;
    font-weight: 700;
    margin: 10px 0;
  }

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
  }
}
</style>
