<template>
  <div class="login-box">
    <van-cell-group>
      <van-form ref="form">
        <van-field v-model="formData.studentName" name="姓名" label="姓名" placeholder="姓名" :rules="[{ required: true, message: '请填写姓名' }]" />
        <van-field v-model="formData.studentPhone" name="电话" label="电话" placeholder="电话" :rules="[{ required: true, message: '请填写电话' }]" />
        <div style="margin: 15px">
          <van-row gutter="20">
            <van-col span="12">
              <van-button round block type="default" @click="onCancel">取消</van-button>
            </van-col>
            <van-col span="12">
              <van-button round block type="danger" @click="onSubmit">生成报告</van-button>
            </van-col>
          </van-row>
        </div>
      </van-form>
    </van-cell-group>
  </div>
</template>

<script>
import Config from "@/api/service";

export default {
  name: "StudentDialog2",
  components: {
  },
  props: {
    studentName: {
      type: String,
      default: '',
    },
    studentPhone: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      formData: {
        studentName: null,
        studentPhone: null,
      },
    };
  },
  methods: {
    //提交
    onSubmit() {
      this.$refs.form.validate().then(() => {
        this.$emit('submit', this.formData);
      }).catch(() => {

      })
    },
    //取消
    onCancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<style scoped>
.login-box {
  width: 100%;
  padding: 10px 0;
}
</style>
