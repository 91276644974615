<template>
  <div class="filter-box">
    <van-cell-group v-if="learningWayList.length > 0" :border="false" title="学习方式">
      <check-border-group v-model="value1" class="check-border-group">
        <check-border v-for="item in learningWayList" :key="item" :label="item" :name="item" class="check-border" icon @click="onClick1"></check-border>
      </check-border-group>
    </van-cell-group>
    <van-cell-group v-if="degreeTypeList.length > 0" :border="false" title="学位类型">
      <check-border-group v-model="value2" class="check-border-group">
        <check-border v-for="item in degreeTypeList" :key="item" :label="item" :name="item" class="check-border" icon @click="onClick2"></check-border>
      </check-border-group>
    </van-cell-group>
    <van-cell-group v-if="belongCategoryList.length > 0 || firstDisciplineList.length > 0 || adjustMajorList.length > 0" title="选择专业">
      <radio-cell-group v-if="belongCategoryList.length > 0" clear v-model="value3" title="所属门类" placeholder="请选择所属门类" style="display: none;" @change="onChange3">
        <radio-cell v-for="(item, index) in belongCategoryList" :key="index" :name="item" :label="item"></radio-cell>
      </radio-cell-group>
      <check-cell-group v-if="firstDisciplineList.length > 0 && value3" clear v-model="value4" title="学科类别" placeholder="请选择学科类别" @change="onChange4">
        <check-cell v-for="(item, index) in firstDisciplineList" :key="index" :name="item" :label="item"></check-cell>
      </check-cell-group>
      <check-cell-group v-if="adjustMajorList.length > 0 && value4.length > 0" clear v-model="value5" title="调剂专业" placeholder="请选择调剂专业" @change="onChange5">
        <check-cell v-for="(item, index) in adjustMajorList" :key="index" :name="item.MajorCode" :label="item.MajorCode + item.MajorName"></check-cell>
      </check-cell-group>
    </van-cell-group>
    <van-cell-group title="选择学校">
      <check-cell-group v-if="aProvinceList.length > 0 || bProvinceList.length > 0" clear v-model="value6" title="学校位置" placeholder="请选择学校位置" @change="onChange6">
        <check-cell v-for="(item, index) in [...aProvinceList, ...bProvinceList]" :key="index" :name="item" :label="item"></check-cell>
      </check-cell-group>
      <radio-cell-group clear v-model="value7" title="院校特色" placeholder="请选择院校特色" @change="onChange7">
        <radio-cell v-for="(item, index) in schoolCharacteristics" :key="index" :name="item" :label="item"></radio-cell>
      </radio-cell-group>
    </van-cell-group>
  </div>
</template>

<script>
import RadioCell from "@/components/MT/RadioCell";
import RadioCellGroup from "@/components/MT/RadioCellGroup";
import CheckCell from "@/components/MT/CheckCell";
import CheckCellGroup from "@/components/MT/CheckCellGroup";
import CheckBorder from "@/components/MT/CheckBorder";
import CheckBorderGroup from "@/components/MT/CheckBorderGroup";
import Config from "@/api/service";
export default {
  name: "SmartAdjustFilter",
  components: {
    RadioCell,
    RadioCellGroup,
    CheckCell,
    CheckCellGroup,
    CheckBorder,
    CheckBorderGroup,
  },
  props: {
    learningWayList: {
      type: Array,
      default: [],
    },
    degreeTypeList: {
      type: Array,
      default: [],
    },
    belongCategoryList: {
      type: Array,
      default: [],
    },
    firstDisciplineList: {
      type: Array,
      default: [],
    },
    adjustMajorList: {
      type: Array,
      default: [],
    },
    aProvinceList: {
      type: Array,
      default: [],
    },
    bProvinceList: {
      type: Array,
      default: [],
    },
    filter1: {
      type: Array,
      default: [],
    },
    filter2: {
      type: Array,
      default: [],
    },
    filter3: {
      type: String,
      default: '',
    },
    filter4: {
      type: Array,
      default: [],
    },
    filter5: {
      type: Array,
      default: [],
    },
    filter6: {
      type: Array,
      default: [],
    },
    filter7: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      schoolCharacteristics: ['985', '211', '双一流', '自划线', '高等院校', '科研院所'],
    };
  },
  computed: {
    value1: {
      get: function () {
        return this.filter1;
      },
      set: function (val) {
        this.$emit('update:filter1', val);
      }
    },
    value2: {
      get: function () {
        return this.filter2;
      },
      set: function (val) {
        this.$emit('update:filter2', val);
      }
    },
    value3: {
      get: function () {
        return this.filter3;
      },
      set: function (val) {
        this.$emit('update:filter3', val);
      }
    },
    value4: {
      get: function () {
        return this.filter4;
      },
      set: function (val) {
        this.$emit('update:filter4', val);
      }
    },
    value5: {
      get: function () {
        return this.filter5;
      },
      set: function (val) {
        this.$emit('update:filter5', val);
      }
    },
    value6: {
      get: function () {
        return this.filter6;
      },
      set: function (val) {
        this.$emit('update:filter6', val);
      }
    },
    value7: {
      get: function () {
        return this.filter7;
      },
      set: function (val) {
        this.$emit('update:filter7', val);
      }
    },
  },
  methods: {
    onClick1() {
      this.value2 = [];
      this.value4 = [];
      this.value5 = [];
      this.value6 = [];
      this.value7 = '';
      this.$emit('filter');
    },
    onClick2() {
      this.value4 = [];
      this.value5 = [];
      this.value6 = [];
      this.value7 = '';
      this.$emit('filter');
    },
    onChange3() {
      this.value4 = [];
      this.value5 = [];
      this.value6 = [];
      this.value7 = '';
      this.$emit('filter');
    },
    onChange4() {
      this.value5 = [];
      this.value6 = [];
      this.value7 = '';
      this.$emit('filter');
    },
    onChange5() {
      this.value6 = [];
      this.value7 = '';
      this.$emit('filter');
    },
    onChange6() {
      this.$emit('filter');
    },
    onChange7() {
      this.$emit('filter');
    },
  },
  mounted() {

  }
};
</script>

<style scoped lang="less">
.filter-box {
  background-color: white;
  margin: 10px;
  padding: 5px;
  border-radius: 10px;
}

.check-border-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.check-border {
  width: 48%;
}

.popup {
  height: 80%;
}

.popup-content {
  margin: 0px 10px 10px 10px;
}

.title-area {
  margin: 20px 0 5px 5px;
  font-size: 14px;
  color: gray;
}

::v-deep .van-cell-group__title {
  color: black;
  font-weight: bold;
}
</style>
